<template>
  <div class="w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button class="btn mr-4" type="button" @click="returnPage">
                <span class="svg-icon">
                  <inline-svg
                    src="/media/svg/icons/Neolex/Basic/arrow-left.svg"
                  />
                </span>
                Hủy
              </b-button>
              <b-button
                v-if="isOwner"
                class="btn mr-4 btn-success"
                type="button"
                @click="changePassword"
              >
                Thay đổi mật khẩu
              </b-button>
              <b-button
                v-if="showBtnActive"
                class="btn btn-primary mr-4"
                type="button"
                @click="showPopupChangeStatus(true)"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
                </span>
                Active
              </b-button>
              <b-button
                v-else
                class="btn btn-danger mr-4"
                type="button"
                @click="showPopupChangeStatus(false)"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
                </span>
                Inactive
              </b-button>
              <b-button
                class="btn btn-success"
                type="button"
                @click="handleValidate"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu lại
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <FormCoachProfile
        ref="form"
        is-update
        :id="id"
        :detail="detail"
        @submit="submit"
      />
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: 'CoachProfileUpdate',
  components: {
    FormCoachProfile: () => import('./components/FormCoachProfile.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  computed: {
    showBtnActive() {
      return !this.detail?.active;
    },
    isOwner() {
      const user = this.$store.state.auth.user;
      return user.id === this.id;
    },
  },
  created() {
    this.getDataPrepare();
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    async getDataPrepare() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/Admin/Account/portal/PrepareCreateUpdateFormItem',
          {
            params: {
              id: this.id,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.detail = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    handleValidate() {
      this.$refs.form.handleValidate();
    },
    async submit(params) {
      try {
        this.loading = true;
        if (params.file) {
          const fileId = await this.uploadImage(params.file);
          if (!fileId) return;
          delete params.file;
          params['coverId'] = fileId;
        }

        const { meta, error } = await this.$api.put(
          `/Admin/Account/portal/${this.id}`,
          params,
        );
        if (meta && !meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.returnPage();
        this.$toastr.s({
          title: 'Thành công !',
          msg: `Chỉnh sửa tài khoản thành công`,
        });
        this.$emit('change-status');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },
    showPopupChangeStatus(state) {
      let text = !state ? 'Inactive' : 'Active';
      let btn = !state ? 'btn-inactive' : 'btn-active';
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn ${text} app user ${this.detail.fullName} không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `${text}`,
            value: true,
            visible: true,
            className: `btn ${btn}`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.$refs.form.submit(state);
          }
        }.bind(this),
      );
    },
    changePassword() {
      const auth = `${process.env.VUE_APP_AUTHORITY}/account/changepassword`;
      const redirectUri = encodeURIComponent(`${window.location.origin}`);
      const params = {
        returnurl: redirectUri,
      };
      // convert objec to a query string
      let qs = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

      window.location.replace(`${auth}?${qs}`);
    },
    async changeStatus(state) {
      this.loading = true;
      const params = {
        id: this.id,
        active: state,
      };
      const { error } = await this.$store.dispatch('CHANGE_STATUS', params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        return this.getDataPrepare();
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-danger::v-deep {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>
